import React from 'react'
import Burger from './Burger'
import { menuItems } from "./links"

function NavbarAlt() {
  return (
    <div>
      <Burger menuItems={menuItems} />
    </div>
  )
}

export default NavbarAlt
