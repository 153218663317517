import * as React from "react";
import { Link } from "gatsby";
import "../style/___main.scss";
import Footer from "../components/Footer";
import NavbarAlt from "../components/NavBars/NavbarAlt";

import Instagram from "../images/Camera.png";
import NameLogo from "../images/Name.png";
import image2 from "../images/image2.jpg";
import playcircle from "../images/PlayCircle.png";
import vioolDoodle from "../images/viool.png";
import vioolTurned from "../images/vioolTurned.png";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function index() {
  return (
    <div className="index-page">
      <div className="header-container">
        <Link to="/">
          <img className="logo-name" src={NameLogo} alt="" />
        </Link>
        <NavbarAlt />
        <h1 className="quote">
          "Het is iedere keer een feest om samen muziek te maken"
        </h1>
        <p className="photographer">
          <img src={Instagram} alt="" />
          Kathinka Faas
        </p>
        <div className="fullNav">
          <ul>
            <li>
              <Link to="/over-ons">Over ons</Link>
            </li>
            <li>
              <Link to="/mogelijkheden">Mogelijkheden</Link>
            </li>
            <li>
              <Link to="/agenda">Agenda</Link>
            </li>
            <li>
              <Link to="/galerij">Galerij</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>

      <section className="index-body">
        <div className="intro-text">
          <div className="text">
            <h3>Wij zijn Duo Juna.</h3>
            <p>
            De Latijnse naam Juna staat voor ‘jongeling’, met als reden dat we een fris duo zijn die voor veel
            mogelijkheden open staat. We spelen graag voor verschillende
            doelgroepen, brengen diverse muziekstijlen over, geven optredens op
            uiteenlopende locaties en experimenteren met gevarieerde presentatie
            vormen, bijvoorbeeld door een verhaal te vertellen met daarin muziek
            verweven. Kortom: we maken ons optreden naar de wensen van onze
            luisteraar. Zo is elk concert uniek. Ons doel is om muziek naar alle
            verschillende luisteraars te brengen: naar de ervaren luisteraar die
            al jaren naar het Concertgebouw gaat of naar mensen die nog nooit
            naar een klassiek concert zijn geweest, naar jong, oud, alles
            ertussenin en met elke mogelijke achtergrond.
            </p>
          </div>

          <div className="violinDoodle">
            <img className="violinDoodle" src={vioolDoodle} alt="" />
          </div>
        </div>

        <LazyLoadImage className="text-image" src={image2} alt="" effect="blur" />

        <div className="second-text">
          <img className="violinDoodle" src={vioolTurned} alt="" />
          <p>
            We willen alle soorten luisteraars bereiken. Zowel de ervaren
            concert-ganger als de buurman en zijn kinderen die nog nooit naar
            een klassiek concert zijn geweest. Kortom: we maken ons optreden
            naar de wensen van onze luisteraar. Zo is elk optreden uniek. Als u
            geïnteresseerd of nieuwsgierig bent geworden óf meer over onze
            opties wilt weten, kijk dan verder op onze site!
          </p>

          <p className="quote">
            "We maken ons optreden naar de wensen van onze luisteraar.”
          </p>
        </div>
      </section>

      <section className="link-to-music">
        <h2>Wil jij onze muziek horen?</h2>
        <Link to="/mogelijkheden" className="link-btn">
          Luister <img src={playcircle} alt="" />
        </Link>
      </section>

      <Footer />
    </div>
  );
}

export default index;
